import React, { Component } from 'react';

type OwnProps = {
  getHeaderRef: (...args: any[]) => any;
  headerIndex: number;
  height: number;
  nHeaders: number;
  onClick?: (...args: any[]) => any;
  scrollbarWidth?: number;
  stickyState?: {
    isStickyAtBottom?: boolean;
    isStickyAtTop?: boolean;
  };
};

const defaultProps = {
  scrollbarWidth: 0,
};

type Props = OwnProps & typeof defaultProps;

export default class ListViewHeader extends Component<Props> {
  static defaultProps = defaultProps;

  static getStickAtTopStyle({ headerIndex, height, scrollbarWidth }: any) {
    return {
      position: 'absolute',
      top: `${headerIndex * height}px`,
      width: `calc(100% - ${scrollbarWidth}px)`,
    };
  }

  static getStickAtBottomStyle({
    headerIndex,
    height,
    nHeaders,
    scrollbarWidth,
  }: any) {
    return {
      position: 'absolute',
      bottom: `${(nHeaders - 1 - headerIndex) * height}px`,
      width: `calc(100% - ${scrollbarWidth}px)`,
    };
  }

  constructor(props: Props) {
    super(props);
    this.onHeaderClick = this.onHeaderClick.bind(this);
  }

  onHeaderClick() {
    const { onClick } = this.props;
    if (onClick) {
      onClick(this.props.headerIndex);
    }
  }

  render() {
    const { getHeaderRef, stickyState } = this.props;

    return (
      <div>
        <div
          role="button"
          className="listview__header"
          ref={getHeaderRef}
          onClick={this.onHeaderClick}
        >
          {this.props.children}
        </div>
        {stickyState && stickyState.isStickyAtTop && (
          <div
            aria-hidden
            role="button"
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"absolute... Remove this comment to see the full error message
            style={ListViewHeader.getStickAtTopStyle(this.props)}
            className="listview__header"
            onClick={this.onHeaderClick}
          >
            {this.props.children}
          </div>
        )}
        {stickyState && stickyState.isStickyAtBottom && (
          <div
            aria-hidden
            role="button"
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"absolute... Remove this comment to see the full error message
            style={ListViewHeader.getStickAtBottomStyle(this.props)}
            className="listview__header"
            onClick={this.onHeaderClick}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}
