import cn from 'classnames';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  collapsed?: boolean;
};

const ListViewContent: React.FC<Props> = ({ children, collapsed = false }) => {
  const cssClasses = cn('listview__content', {
    'listview__content--collapsed': collapsed,
  });
  return <div className={cssClasses}>{children}</div>;
};

export default ListViewContent;
