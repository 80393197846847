import qs from 'qs';
import React from 'react';
import { Provider } from 'react-redux';

import App from './containers/App';
import Page404 from './containers/Page404';
import { isTeachPreview } from './helpers/isTeachPreview';
import { initializeI18n } from './i18n';
import * as actions from './redux/actions';
import createStore from './redux/createStore';

const renderApp = ({
  Components,
  history,
  initialState,
  language,
  middlewares,
}: any) => {
  const isPreview = isTeachPreview(history.location.pathname);
  const PREVIEW = '/preview';

  const store = createStore({ initialState, history, middlewares });

  initializeI18n(language);

  history.listen((location: any) => {
    const { pathname, search } = location;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    store.dispatch(actions.updateLocation({ pathname, query }));
  });

  if (isPreview) {
    // force all the url to start with /preview
    history.listen(({ pathname, search }: any) => {
      if (!pathname.match(/\/preview\//)) {
        history.replace(
          `${pathname.replace('/courses', `${PREVIEW}/courses`)}${search}`,
        );
      }
    });
  }

  const rootElement = (
    <Provider store={store}>
      <Components.Router history={history}>
        <Components.Switch>
          <Components.Route
            exact
            path="/:lang(es)?/:previewMode(preview)?/courses/:courseRef/:chapterRef?"
            render={(props: any) => <App {...props} language={language} />}
          />
          <Components.Route
            exact
            path="/:lang(es)?/campus/:previewMode(preview)?/courses/:courseRef/:chapterRef?"
            render={(props: any) => <App {...props} language={language} />}
          />
          <Components.Route component={Page404} />
        </Components.Switch>
      </Components.Router>
    </Provider>
  );

  return { rootElement, store };
};

export default renderApp;
