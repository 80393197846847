import React from 'react';

type Props = {
  attributes?: {
    [key: string]: string;
  };
  children?: string | number | React.ReactElement | React.ReactNode;
  headerHeight: number;
  isLast: boolean;
  sectionIndex: number;
};

/**
 * The last section height should be calculated in order to allow it to fill
 * the entire list view. This allows all sections to have a sticky header
 * at the top even although the content does not fill everything.
 */
const ListViewSection: React.FC<Props> = ({
  sectionIndex,
  children,
  isLast,
  headerHeight,
  attributes = {},
}) => {
  const style = isLast
    ? { minHeight: `calc(50% - ${headerHeight * sectionIndex}px)` }
    : null;

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'CSSProperti... Remove this comment to see the full error message
    <div className="listview__section" style={style} {...attributes}>
      {children}
    </div>
  );
};
export default ListViewSection;
