export const TRANSLATED_COURSES = [
  16459,
  29302,
  735,
  58,
  31950,
  23983,
  799,
  29303,
  24388,
  22066,
  24372,
  29304,
  28314,
  24907,
  22812,
  25412,
  13367,
  30656,
  29355,
  3629,
  4452,
  6612,
  7355,
  19197,
  19930,
  20692,
  21544,
  24558,
  29490,
  29533,
  29835,
  32428,
  32476,
  22639,
  13706,
  36160,
  34598,
  34857,
  672,
  4914,
  24252,
  13371,
  1532,
  29478,
  33554,
  13185,
  16921,
  4267,
  29902,
  33848,
  6079,
  14989,
  31939,
  32439,
  32245,
  33412,
  29830,
  28826,
  28921,
  20822,
  25475,
  13274,
  27391,
  24852,
  13690,
  19854,
  24364,
  13369,
  14519,
  32740,
  28767,
  2072,
  27336,
  26827,
  30891,
  16937,
  32623,
  28173,
  34614,
  5065,
  17602,
  29744,
  15876,
  1975,
  33409,
  1796,
  15424,
  32932,
  6280,
  1531,
  13698,
  28318,
  1606,
  1607,
  29712,
  29094,
  15192,
  25472,
  1477,
  28169,
  29847,
  29573,
  33509,
  28303,
  29157,
  32509,
  6576,
  29081,
  6919,
  25942,
  21394,
  34425,
  25473,
  33286,
  29143,
  29092,
  32613,
  6199,
  24098,
  29453,
  13023,
  2906,
  4205,
  28944,
  14739,
  13203,
  16470,
  35064,
  22723,
  23080,
  3423,
  28765,
  31794,
  24878,
  20891,
  34961,
  36398,
  33893,
  33674,
  35684,
  30563,
  36164,
];
